.sectionContainer {

    background-color: #ffffff;
    padding: 30px 0;

    > div {
        height: 100%;

        .sectionHeaderContainer {
            width: 100%;
            height: auto;

            > h1.sectionHdrTitle {
                font-size: 18px;
                font-weight: 500;
                color: rgba($color: #000000, $alpha: 0.5);
                padding: 12px 0;
            }
        }

        .sectionContentContainer {
            width: 100%;
            height: auto;

            .toolsListContainer {
                width: 100%;
                height: 100%;

                margin: 0 auto;
                position: relative;
                width: calc(100% + 28px);
                left: -14px;
                top: -14px;
            }
        }

    }

}

.toolsListContainer {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
}

.toolList {
    // width: 100%;
    // max-width: 360px;
    // min-width: 300px;
    // margin: 0 auto;
    // margin-top: 20px;
    // margin-bottom: 20px;
    width: calc((100% / 3) - (14px * 3));
    margin: 14px;
    height: fit-content;
    background-color: #FFFFFF;
    border: 1px solid #F6F6F6;
    border-radius: 13px;
    list-style: none;
    overflow: hidden;
    opacity: 1;

    transition: box-shadow 0.2s ease-out;

    @include media('<1000px') {
        width: calc((100% / 2) - (14px * 2)); 
    }

    @include media('<560px') {
        width: calc((100% / 1) - (14px * 1)); 
    }

    > .toolListInner {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > .toolHeader {
            width: 100%;
            padding: 14px 0;
            background-color: #ffffff;

            > .toolHeaderInner {
                width: calc(100% - 40px);
                height: 100%;
                margin: 0 auto;

                display: flex;
                display: -webkit-flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
    

                > .iconContainer {
                    margin-right: 12px;
                    display: flex;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                > .textContainer {
                    > span {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba($color: #000000, $alpha: 0.7);
                    }
                }
            }

        }

        
        > .toolContent {
            width: 100%;
            height: 100%;
            background-color: #F6F6F6;

            transition: background-color 0.2s ease-out;

            padding: 14px 0;

            > .toolContentInner {
                width: calc(100% - 40px);
                height: 100%;
                margin: 0 auto;
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                > p {
                    color: rgba($color: #000000, $alpha: 0.4);
                    font-size: 18px;
                }

                .toolPriceContainer {
                    width: 100%;
                    height: auto;
                    margin-top: 12px;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;

                    > .icon {
                        margin-right: 8px;
                    }

                    .boldCoinText {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }

    }

    &:hover {
        // background-color: #dadada;
        box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1);
        opacity: 1;

        > .toolListInner {
            > .toolContent {
                background-color: #ffffff;
                // border-radius: 13px;
                box-shadow: inset 0 2px 0 0 #F6F6F6;
            }
        }
    }
}