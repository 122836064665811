.stadiaNavigationOuter {
    
    height: auto;
    padding: 8px 0;
    background-color: #F6F6F6;

    z-index: 99999;

    &._2nd {
        padding-bottom: 12px;
    }

    >div {   
        height: 100%;
        
        .navigationLinksContainerRight {
            > li {
                margin-right: 20px;
                list-style: none;

                > h2.branding {
                    color: rgba(0, 70, 174, 0.22);
                    font-size: 26px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
}

.accountCard {

    > span {

        display: inline-flex;
        display: -webkit-inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.imgContainer {
            margin-right: 16px;
    
            > img {
                width: 38px;
                height: 38px;
                border-radius: 38px;
            }
        }
    
        &.nameContainer {
            font-size: 17px;
            font-weight: 700;
        }

    }
}


.coinCard {
    border-radius: 38px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFFFFF;
    height: 40px;
    padding: 0 12px;

    > span {

        display: inline-flex;
        display: -webkit-inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.imgContainer {
            margin-right: 8px;
    
            > img {
                width: 38px;
                height: 38px;
                border-radius: 38px;
            }
        }
    
        &.nameContainer {
            font-size: 17px;
            font-weight: 700;
        }

    }
}

.navigationLink {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;

    &.active {
        color: #24A3FF;
    }
}

.navigationSeprator {
    display: block;
    width: 2px;
    height: 16px;
    margin: 0 12px;
    background-color: rgba(0, 0, 0, 0.1);
}

.navigationSearchBar {
    width: auto;
    max-width: 100%;
    width: 500px;
    height: 44px;
    background-color: #FFFFFF;
    border-radius: 44px;
    overflow: hidden;

    transition: box-shadow 0.2s ease-out;

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .iconContainer {
            width: auto;
            min-width: 44px;
            width: 50px;
            height: 100%;
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .inputContainer {
            width: 100%;
            height: 100%;

            > input {
                width: 100%;
                height: 100%;
                border: none;
                font-size: 19px;
                padding: 0 4px;
                padding-right: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }

    &:focus-within {
        box-shadow: 0 0 0 4px #24A3FF;
    }
}

.mR20 {
    margin-right: 20px;
}