@import url('https://fonts.googleapis.com/css2?family=Impact:wght@400;600&display=swap&family=Inter:wght@400;500;600&display=swap');

@font-face {
    font-family: 'InaiMathi';
    src: url('./InaiMathi.ttf')  format('truetype'), /* Safari, Android, iOS */
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'InaiMathi', sans-serif;

    &:focus {
        outline: none;
    }
}

body {
    overflow-x: hidden;
    // max-width: 1400px;
    margin: 0 auto;
}

.ctW100 {
    width: 100%;
}

.ctWMax {
    width: max-content;
}

.cH100 {
    height: 100%;
}

.ctWAuto {
    width: auto;
}

.ctMain {
    width: 100%;
    height: auto;

    > .ctMainInner {
        width: calc(100% - 40px);
        max-width: 1200px;
        margin: 0 auto;
    }
}

.dFlex {
    display: flex;
    display: -webkit-flex;
}

.dDirRow {
    flex-direction: row;
}

.dDirCol {
    flex-direction: column;
}

.jS {
    justify-content: flex-start;
}

.jSb {
    justify-content: space-between;
}

.jE {
    justify-content: flex-end;
}

.aS {
    align-items: flex-start;
}

.aC {
    align-items: center;
}

@import "include_media";
@import "navigation";
@import "footer";

@import "stadia/navigation";
@import "stadia/section";

// section {
//     width: 100vw;
//     height: 100vh;

//     background-color: rgb(255, 255, 255);
//     &:nth-child(odd) {
//         background-color: rgb(236, 236, 236);
//     }
// }