:root {
  --inner-margin: 200px;

  @include media("<1050px") {
    --inner-margin: 100px;
  }

  @include media("<750px") {
    --inner-margin: 60px;
  }
}

.navigation-outer {
  width: 100%;
  height: 68px;

  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  backdrop-filter: blur(80px);

  padding-bottom: 20px;
  height: 88px;

  &.scrolled {
    padding-bottom: 20px;
    height: 88px;
  }

  > .inner {
    width: calc(100% - var(--inner-margin));
    max-width: 1800px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .section {
      width: 100%;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        width: auto;
        justify-content: flex-end;
      }

      &.mobile-only {
        display: none;

        @include media("<850px") {
          display: flex;
        }
      }

      &.links-container {
        @include media("<850px") {
          display: none;

          position: fixed;
          width: calc(100% - 150px);
          height: 100vh;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba($color: #000000, $alpha: 1);
          transform: translateX(100%);
          transition: transform 0.4s ease;

          &.active {
            display: block;
            transform: translateX(0);
          }

          .subsection {
            width: 100%;
            height: 100%;

            &:first-child {
              display: none;
            }

            > ul {
              width: 100%;
              height: 100%;
              display: flex;
              display: -webkit-flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              > li {
                > a {
                  color: #ffffff !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 16px;
                  padding-right: 64px;
                  cursor: pointer;

                  &:hover {
                    background-color: #ffffff;
                    color: #ff8a00 !important;
                  }
                }
              }
            }
          }
        }
      }

      > .subsection {
        width: auto;
        height: 100%;

        &.menu-humb-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;
        }

        > .menu-humb {
          width: auto;
          min-width: 60px;
          height: 40px;
          display: flex;
          display: -webkit-flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          border: none;
          background-color: transparent;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }

          > span {
            display: flex;
            width: 100%;
            height: 10px;
            background-color: #ff8a00;

            &:nth-child(2) {
              background-color: transparent;
              height: auto;
              font-size: 18px;
              justify-content: flex-end;
            }
          }
        }

        > .logo {
          width: 100%;
          height: 100%;
          display: flex;
          display: -webkit-flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          position: relative;

          > span {
            font-family: "Impact", sans-serif;
            font-size: 22px;
          }

          &::after {
            content: ".IN";
            color: #ffffff;
            position: absolute;
            display: flex;
            width: 20px;
            height: 100%;
            background-color: #ff8a00;
            right: -25px;
            bottom: 4px;
            text-align: center;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          }
        }

        > .links {
          width: 100%;
          height: 100%;
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;

          > li {
            margin-right: 50px;
            list-style: none;

            &:last-child {
              margin-right: 0;
            }

            a {
              font-size: 17px;
              color: rgba($color: #000000, $alpha: 1);
              text-decoration: none;

              &.main-link {
                background-color: #ff8a00;
                padding-left: 16px;
                color: #ffffff;
                font-size: 20px;

                &:hover {
                  color: #ffffff;
                }
              }

              &:hover {
                color: #ff8a00;
              }
            }
          }
        }
      }
    }
  }
}

/**

Logo width - 61px
Padding left - 20px

*/

.page-header {
  margin-top: 40px;
  width: 100%;
  min-height: 40px;
  height: fit-content;
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  display: -webkit-flex;

  &.with-bg {
    background-color: #fff5e9;
    padding: 80px 0;
  }

  > .inner {
    width: calc(100% - 80px);
    margin: 0 auto;
    height: 100%;
    margin-left: calc(60px + 40px + 40px);
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // to remove extra left margin
    margin-left: 40px;

    @include media("<850px") {
      margin-left: 40px;
    }

    > .page-title {
      font-size: 24px;

      &::before {
        content: "";
        display: block;
        position: relative;
        height: 10px;
        width: 80%;
        background-color: #ff8a00;
        top: -10px;
      }
    }
  }
}

.page-section {
  width: 100%;
  height: 60vh;
  // padding-left: calc(60px + 40px + 40px);

  // to remove extra left and right margin
  // padding: 40px;
  // padding-right: 0;


  // @include media("<850px") {
  //   padding: 40px;
  //   padding-right: 0;
  // }

  > .inner {
    width: calc(100% - var(--inner-margin));
    margin: 0 auto;
    height: 100%;

    margin: 0 auto;
    max-width: 1800px;  

    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .sector {
      width: 50%;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &.sector-1 {
        justify-content: flex-start;
        align-items: flex-start;
        width: 40%;

        > .img-placeholder {
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.01);
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          > img {
            width: 80%;
            height: 80%;
          }
        }
      }

      &.sector-2 {
        width: 60%;
        align-items: flex-end;
        justify-content: center;

        // padding-right: 120px;

        > div {
          width: 90%;
          height: auto;
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          h1 {
            font-size: 40px;
          }

          p {
            font-size: 20px;
            color: #707070;
          }

          button {
            padding: 12px 24px;
            background-color: #000000;
            border: none;
            color: #ffffff;
            font-size: 20px;
          }

          h1,
          p {
            text-align: right;
          }
        }
      }
    }

    @include media("<900px") {
      flex-direction: column;

      .sector {
        width: 100%;

        &.sector-1 {
          width: 100%;

          > .img-placeholder {
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &.sector-2 {
          margin-top: 20px;

          width: 100%;
          align-items: flex-start;
          justify-content: flex-start;

          > div {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }

  &.flip-x {
    > .inner {
      flex-direction: row-reverse;

      @include media("<900px") {
        flex-direction: column;
      }

      .sector {
        &.sector-1 {
          align-items: flex-end;

          > .img-placeholder {
            justify-content: flex-end;
          }
        }

        &.sector-2 {
          align-items: flex-start;

          > div {
            flex-direction: row-reverse;

            h1,
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }

  @include media("<900px") {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

#hero {
  width: 100%;
  // height: 80vh;
  height: auto;
  background-color: #f4f4f4;

  > .inner {
    width: calc(100% - var(--inner-margin));
    margin: 0 auto;
    height: 100%;

    max-width: 1800px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;

    padding: 67px 0;

    .header-section {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      h1 {
        color: #000;
        text-align: left;
        font-family: Impact;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: left;
        font-family: InaiMathi;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .content-section {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 22px;

      .slider-card {
        // width: calc(100% / 2);
        flex: 1;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        background-color: rgba($color: #000000, $alpha: 0.1);

        &.active {
          background-color: #ffffff;
        }

        > .inner {
          width: calc(100% - 76px);
          margin: 0 auto;
          padding: 45px 0;
          align-items: flex-end;
          align-content: flex-end;
          gap: 55px 188px;
          flex-wrap: wrap;

          > .card-header-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            > div {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 22px;

              &.auto-w {
                width: auto;
              }

              &.card-img-container {
                padding-bottom: 20px;
                padding-left: 20px;

                img {
                  width: 120px;
                }
              }

              &:last-child {
                align-items: flex-end;
              }

              h3 {
                width: 100%;
                color: #000;
                text-align: left;
                font-family: Inter;
                font-size: 19px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              p {
                width: 100%;
                color: #000;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
              }
            }
          }

          > .content-section {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 188px;

            a {
              color: #ff8a00;
              text-align: right;
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;
            }

            // button {
            //   display: flex;
            //   padding: 14px 28px;
            //   justify-content: center;
            //   align-items: center;
            //   gap: 10px;
            //   background: #ff8a00;
            //   border: none;
            //   cursor: pointer;

            //   span {
            //     color: #fffffe;
            //     text-align: left;
            //     font-family: Inter;
            //     font-size: 18px;
            //     font-style: normal;
            //     font-weight: 500;
            //     line-height: normal;
            //   }
            // }
          }
        }
      }
    }
  }
}

#contact {
  width: 100%;
  height: auto;
  background: #5c431e;

  > .inner {
    width: calc(100% - var(--inner-margin));
    margin: 0 auto;
    height: 100%;

    max-width: 1800px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;

    padding: 60px 0;

    .header-section {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      h1 {
        color: #ffffff;
        text-align: right;
        font-family: Impact;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: right;
        font-family: InaiMathi;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .content-section {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      gap: 22px;

      > div {
        width: 100%;

        &.contact-form-container {
          display: flex;
          flex-direction: column;
          gap: 30px;
          width: calc(100% - 40%);
          .header {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h2 {
              font-size: 26px;
              color: #ffffff;
            }
            p {
              font-style: italic;
              font-size: 16px;

              color: rgba(255, 255, 255, 0.76);
            }
          }
          .content {
            width: 100%;
            height: 100%;
          }
        }
        &.live-chat-option {
          width: 40%;

          > .inner {
            width: calc(100% - 40px);
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            > div {
              width: 100%;
              height: 100%;

              &:first-child {
                width: auto;
                padding: 0 12px;

                span {
                  font-size: 24px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: normal;
                  color: rgba(255, 255, 255, 0.76);
                }
              }

              &:last-child {
                padding: 0 12px;
                padding-left: 24px;
                h3 {
                  color: #FF8A00;
                  font-size: 24px;
                }

                p {
                  color: rgba($color: #ffffff, $alpha: 0.7);
                  font-style: italic;
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      @include media("<1050px") {
        flex-direction: column;

        > div {
          &.contact-form-container {
            width: 100%;
          }
          &.live-chat-option {
            width: 100%;
            display: none;
          }
        }
      }
    }
  }
}


.footer {
  width: 100%;
  height: 180px;

  > .inner {
    width: calc(100% - var(--inner-margin));
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      gap: 80px;

      > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        list-style: none;

        span {
          font-size: 17px;
          font-weight: 500;
          color: #000000;
        }

        a {
          font-size: 17px;
          color: rgba($color: #000000, $alpha: 0.4);
          text-decoration: none;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

          &:hover {
            color: rgba($color: #000000, $alpha: 0.8);
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
          }
        }
      }

      &.w-auto {
        width: max-content;
        text-wrap: nowrap;
        justify-content: flex-end;
      }
    }

    .main-link {
      background-color: #ff8a00;
      padding-left: 16px;
      color: #ffffff;
      font-size: 20px;

      font-size: 17px;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }
  }
}


.contact-form {
  > .inner-contact-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    > .fields-rows {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      input {
        width: 100%;
        height: 60px;
        padding: 0 32px;
        font-size: 17px;

        &::placeholder {
          font-style: italic;
        }
      }

      textarea {
        width: 100%;
        height: auto;
        padding: 24px 32px;
        font-size: 17px;
        height: 180px;
        resize: none;

        &::placeholder {
          font-style: italic;
        }
      }

      button {
        width: 100%;
      }
    }
  }
}

button.style-1 {
  display: flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ff8a00;
  border: none;
  cursor: pointer;

  span {
    color: #fffffe;
    text-align: left;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
