.footerBar {
    width: 100%;
    height: auto;
    padding: 30px 0;
    z-index: 99999;
    border-top: 1px solid rgba(0, 0, 0, 0.04);

    &.pBottom {
        position: fixed;
        bottom: 0;
    }

    .brandText {
        color: #AC9EAE;
        font-weight: 400;
        font-size: 14px;
    }
    
    .brandContact {
        color: #AC9EAE;
        font-weight: 400;
        font-size: 13px;
        margin-top: 3px;
        opacity: 0.8;
        text-transform: lowercase;
    }

    .footerLinksContainer {
        > li {
            // margin-right: 10px;
            list-style: none;

            &.seprator {
                height: 100%;

                > div {
                    width: 1px;
                    height: 12px;
                    margin: auto 12px;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &:last-child {
                margin-right: 0;
                border-right: 0;
            }
        }
    }
}